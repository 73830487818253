// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapboxgl-popup {
    font-family: 'Roboto', sans-serif;
}

/* fish-site popup formatting*/
.fish-site-popup .mapboxgl-popup-content {
    width: 450px;
    background-color: #fbfbfb;
    padding: 12px;
}

.fish-site-popup .mapboxgl-popup-content #top {
    margin-left: 5px;
    font-size: 15px;
    margin-bottom: 8px;
}

.fish-site-popup .mapboxgl-popup-content #title {
    font-size: 17px;
}

.fish-site-popup .mapboxgl-popup-content #bottom {
    background-color: #e5e7eb;
    margin-top: 5px;
    padding: 5px 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 14px;
    border-radius: 5px;
}

.fish-site-popup .mapboxgl-popup-content a {
    outline: none;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/Map.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;;AAEA,8BAA8B;AAC9B;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB;;kBAEc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".mapboxgl-popup {\n    font-family: 'Roboto', sans-serif;\n}\n\n/* fish-site popup formatting*/\n.fish-site-popup .mapboxgl-popup-content {\n    width: 450px;\n    background-color: #fbfbfb;\n    padding: 12px;\n}\n\n.fish-site-popup .mapboxgl-popup-content #top {\n    margin-left: 5px;\n    font-size: 15px;\n    margin-bottom: 8px;\n}\n\n.fish-site-popup .mapboxgl-popup-content #title {\n    font-size: 17px;\n}\n\n.fish-site-popup .mapboxgl-popup-content #bottom {\n    background-color: #e5e7eb;\n    margin-top: 5px;\n    padding: 5px 10px;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n        sans-serif;\n    font-size: 14px;\n    border-radius: 5px;\n}\n\n.fish-site-popup .mapboxgl-popup-content a {\n    outline: none;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
