// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border-collapse: collapse;
    width: 100%;
  }
  
  table td,
  table th {
    border: 1px solid #ddd;
  }
  
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  table tr:hover {
    background-color: #ddd;
  }`, "",{"version":3,"sources":["webpack://./src/components/PreviousTrips/table.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;EACb;;EAEA;;IAEE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["table {\n    border-collapse: collapse;\n    width: 100%;\n  }\n  \n  table td,\n  table th {\n    border: 1px solid #ddd;\n  }\n  \n  table tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  table tr:hover {\n    background-color: #ddd;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
